import { useMemo } from 'react';
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from 'apollo-link-ws';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

const wsLink = process.browser
  ? new WebSocketLink({
      uri: process.env.NEXT_PUBLIC_BACKEND_WSS,
      options: {
        reconnect: true,
        lazy: true
      }
    })
  : null;
const httplink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_BACKEND_API,
  credentials: 'same-origin'
});

const link = process.browser
  ? split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httplink
    )
  : httplink;

/* eslint-disable no-underscore-dangle */
function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Message: {
          fields: {
            answers: {
              merge(existing = [], incoming = []) {
                if (!incoming) return existing;
                const newMessage = [];
                incoming.forEach((incoming) => {
                  const alreadyExist = existing.some(
                    (existing) => existing.__ref === incoming.__ref
                  );
                  if (!alreadyExist) newMessage.push(incoming);
                });
                return [...newMessage, ...existing];
              }
            }
          }
        },
        Query: {
          fields: {
            messageFeed: {
              keyArgs: false,
              merge(existing, incoming /* { args } */) {
                // TO-DO manage the update and the delete
                // if cursor, the request is coming from the fetchMore
                // if not, the request come from a subscription
                // const fromSubscription = args?.cursor ? true : false;
                if (!incoming) return existing;
                if (!existing) return incoming;
                const newMessage = [];
                incoming.messages.forEach((incoming) => {
                  const alreadyExist = existing.messages.some(
                    (existing) => existing.__ref === incoming.__ref
                  );
                  if (!alreadyExist) newMessage.push(incoming);
                });
                return {
                  ...incoming,
                  messages: [...newMessage, ...existing.messages]
                };
              }
            }
          }
        }
      }
    })
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        )
      ]
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
/* eslint-enable no-underscore-dangle */

export function addApolloState(client, pageProps) {
  const newPageProps = { ...pageProps };
  if (pageProps?.props) {
    newPageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return newPageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
